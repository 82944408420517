/* @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600&display=swap');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css'); */
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css'); */

@import url("assets/fonts/fontawesome.min.css");

@import url("assets/fonts/bootstrap.min.css");

@font-face {
  font-family: "Rajdhani";
  src: url("assets/fonts/Rajdhani-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rajdhani";
  src: url("assets/fonts/Rajdhani-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Orbitron";
  src: url("assets/fonts/Orbitron-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Orbitron";
  src: url("assets/fonts/Orbitron-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "PTMono";
  src: url("assets/fonts/PTMono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  height: 100%;
  font-family: "PTMono", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  /* background: #141d2b; */
  /* background: #ffffff; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#fancy-background {
  color: var(--background, #141d2b);
}

::selection {
  /* background: var(--primary, #9fef00); */
  /* color: var(--background, #ffffff); */
  /* color: var(--background, #141d2b); */
}

svg.rsm-svg {
  background: #ffffff;
  border-radius: 6px;
}

:root {
  --toastify-color-dark: #111927 !important;
  --toastify-color-info: #04e4f4 !important;
  --toastify-color-success: #20e253 !important;
  --toastify-color-warning: #f6f000 !important;
  --toastify-color-error: #f80363 !important;
}
